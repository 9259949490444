import React,  { Component, Fragment } from 'react';
import {QuickLink} from '../common/QuickAction';
export class GraphicsSubmenu extends Component {
    render() {
        return (
           <Fragment>
                   <div className="breadcum">
        <QuickLink goto="/GraphicsDesign" label="Ilustration" icon="ews ewse_whatsapp"/>
        <QuickLink goto="/design" label="Ilustration" icon="ews ewse_whatsapp"/>
        <QuickLink goto="/design" label="Pramod" icon="ews ewse_whatsapp"/>
        <QuickLink goto="/design" label="Pramod" icon="ews ewse_whatsapp"/>
          </div>
           </Fragment>
        )
    }
}


