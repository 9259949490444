import React,  { Component, Fragment } from 'react';
import {GetQuoteb, WhatsApp, Portfolio, Pricing} from '../common/QuickLinksList';
import {GraphicsSubmenu} from "../graphics/GraphicsSubmenu";

class GraphicsDesign extends Component {
    render() {
      var currentLoc = window.location.pathname;
        return (
            <Fragment>
            
            <div className="contentBox innerpage"> 
      <div className="bennerImage transBg">
        <img src={process.env.PUBLIC_URL + "./assets/img/seo.svg"} alt="" className="scaleanimtion" />
      </div>
      
      <div className="contentText">
          <div className="contentScroll">
        <h1><i className= "ews ewse_gaphicsdes" /> Graphics Design</h1>
        <span className="customPara">
            <div className="leftline">
        <p>An exclusive communication design can project your business ideas with the help of graphics. Explosion web has a team of experienced graphic designers who convert your business mission into stylish textual and visual content. A great graphic design, for your business theory, attracts customers by creating visual impressions. Talk to us about your business plans and we will design the best business graphics for your projects.
      </p>
       <ul className="ulList">
         <li>
           <p>
           An exclusive communication design can project your business ideas with the help of graphics.
           </p>
         </li>
         <li>
           <p>
           An exclusive communication design can project your business ideas with the help of graphics. Explosion web has a team of experienced graphic designers who convert your business mission into stylish textual and visual content. A great graphic design, for your business theory, attracts customers by creating visual impressions. Talk to us about your business plans and we will design the best business graphics for your projects.
           </p>
         </li>
         <li>
           <p>
           An exclusive communication design can project your business ideas with the help of graphics. Explosion web has a team of experienced graphic designers who convert your business mission into stylish textual and visual content. A great graphic design, for your business theory, attracts customers by creating visual impressions. Talk to us about your business plans and we will design the best business graphics for your projects.
           </p>
         </li>
       </ul>
        </div>
        </span>
       <GraphicsSubmenu/>
          </div>
      </div>
    </div>
    <div className="quickActionButton">
      <ul>
<Portfolio pagelink =''/>
<Pricing pagelink =''/>
<GetQuoteb/>
<WhatsApp/>
      </ul>
    </div>      
     </Fragment>
        )
    }
}

export default GraphicsDesign