import React,  { Component, Fragment } from 'react';
import {GetQuoteb, WhatsApp} from '../common/QuickLinksList';

class Home extends Component {
    render() {
    
        return (
           <Fragment>
           
              <div className="contentBox">
            <div className="bennerImage">
              <img src={process.env.PUBLIC_URL + "./assets/img/bird.svg"} alt="" />
            </div>
            <div className="bannerText">
            <div class="cube">
    <div class="cube__face cube__face--front">  <h1>Design</h1>
              <p>is intelligence made visible</p></div>
    <div class="cube__face cube__face--back">  <h1>Mobile</h1>
              <p>is intelligence made visible</p></div>
    <div class="cube__face cube__face--top"><h1>SEO</h1>
              <p>is intelligence made visible</p></div>
    <div class="cube__face cube__face--bottom"><h1>Devlopment</h1>
              <p>is intelligence made visible</p></div>
  </div>
            
            </div>
          </div>   
          <div className="quickActionButton">
            <ul>
       <GetQuoteb/>
       <WhatsApp/>
            </ul>
          </div>  
           </Fragment>
        )
    }
}

export default Home