import React, { Component, Fragment } from 'react';
import { GetQuoteb, WhatsApp } from '../common/QuickLinksList';
import FormFeildsData from './FormData/FormFeildsData';
class GetQuote extends Component {
  constructor(){
    super();
  this.state={
    secondlevelServices:[], 
  }
  }
  handleChangeFirstLevel = (e) =>{
   alert(e.target.value);
  }
  render() {
    return (
      <Fragment>

        <div className="contentBox innerpage">
          <div className="bennerImage scaleanimtion">
            <img src={process.env.PUBLIC_URL + "./assets/img/bird.svg"} alt="" className="scaleanimtion" />
          </div>

          <div className="contentText">
            <div className="contentScroll">
              <h1><i className="ews ewse_getquote" /> Get Quote</h1>
              <span className="customPara">
                <div className="leftline">
                  <form>
                    <div className="row">
                      <div className="col-6 col-sm-12">
                        <div className="formContent flex">
                          <label htmlFor="name">Name</label>
                          <input type="text" id="name" name="name" placeholder="Your Name..." />
                        </div>
                      </div>
                      <div className="col-6 col-sm-12">
                        <div className="formContent flex">  <label htmlFor="mobile">Mobile</label>
                          <input type="number" id="mobile" name="mobile" placeholder="Your Mobile..." /></div>
                      </div>
                      <div className="col">
                        <div className="formContent flex">
                          <label htmlFor="Email">Email</label>
                          <input type="email" id="Email" name="Email" placeholder="Your Email..." />
                        </div>
                      </div>
                      <div className="col">
                        <div className="formContent">
                          <h4>Select Services</h4>
                          <ul className="radibutton flex">
                          {FormFeildsData.map((item, index) => {
                            return <li key={index}>
                              <label className="container">{item.label}
                              <input type="radio" name="sevice_level_1" defaultChecked={item.defaultChecked} value={item.value} onChange={this.handleChangeFirstLevel}  />
                                <span className="checkmark" />
                              </label></li>  
                            })}   
                          </ul>
                        </div>
                      </div>
                      <div className="col">
                        <div className="formContent">
                          <h4>Select Graphics Design Services</h4>
                          <ul className="radibutton buttonstyle flex">
                          {FormFeildsData.map((item, index) => {
                            return <li key={index}>
                              <label className="container">{item.label}
                              <input type="radio" name="sevice_level_2" defaultChecked={item.defaultChecked} defaultValue={item.value}  />
                                <span className="checkmark" />
                              </label></li>  
                            })}   
                            {/* <li>
                              <label className="container">Logo
                              <input type="radio" name="servics" defaultValue="Graphics Design" defaultChecked />
                                <span className="checkmark" />
                              </label></li>
                            <li>
                              <label className="container">Brocher/catelog
                              <input type="radio" name="servics" defaultValue="Web Devolopment" />
                                <span className="checkmark" />
                              </label></li>
                            <li>
                              <label className="container">Illustration
                              <input type="radio" name="servics" defaultValue="SEO" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="container">Creative Post
                              <input type="radio" name="servics" defaultValue="SEO" />
                                <span className="checkmark" />
                              </label>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col">
                        <div className="formContent">
                          <h4>Select Logo Services</h4>
                          <ul className="radibutton block">
                            <li>
                              <label className="container">Iconic Logo
                              <input type="radio" name="other" defaultValue="Graphics Design" defaultChecked />
                                <span className="checkmark" />
                              </label></li>
                            <li>
                              <label className="container">Text Based Logo
                              <input type="radio" name="other" defaultValue="Web Devolopment" />
                                <span className="checkmark" />
                              </label></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="quickActionButton">
          <ul>
            <WhatsApp />
          </ul>
        </div>
      </Fragment>
    )
  }
}
export default GetQuote