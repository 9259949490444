import Home from './pages/Home';
import GraphicsDesign from './pages/GraphicsDesign';
import GetQuote from './pages/GetQuote';
import LogoDesign from './graphics/LogoDesign';
import { Switch, Route, useLocation } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import SideHeader from './common/SideHeader';

function App() {
  let history = useLocation();
  const [ spinner, setSpinner ] = useState(true);
  useEffect(() => {
    setSpinner(false);
  }, []);
   if(spinner){
     return <div>Loading</div>
   }
  return (
    <Fragment>
    
      {/*Bg layer1 */}
      <div className="homebg">
        <img src={process.env.PUBLIC_URL + "./assets/img/bg.svg"} alt="" />
      </div>
      {/*bannerTextOutLIne  */}
      <div className={history.pathname === '/' ? 'bannerTextOutLIne' : 'bannerTextOutLIne sidBG animateSider'}>
      </div>

      {/* navLayer */}
      <div className="mainwraper">

        {/* sidHeader */}
        <SideHeader />
        {/* content*/}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/GraphicsDesign" component={GraphicsDesign} />
          <Route path="/GetQuote" component={GetQuote} />
          <Route path="/GraphicsDesign/LogoDesign" component={LogoDesign} />
        </Switch>
      </div>
    </Fragment>

  );
}

export default App;
