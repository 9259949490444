import {NavLink} from "react-router-dom";

export function QuickAction(props) {
    return ( <NavLink to={ props.link }><li>{ props.label ? props.label != '' ? <span> {props.label} </span> : '' : ''}
     { 
        props.icon ? props.icon != '' ? <i className={props.icon}></i> : '' : ''
        }
    </li></NavLink> )
  }
  export function QuickLink(props) {
    return(<NavLink to={props.goto} className="sublinks" activeClassName="active">
    <div><i className={props.icon}></i> {props.label}</div>

  </NavLink>) 
  }

  
