import React,  { Component, Fragment } from 'react';
import Logo from './Logo';
class LogoDesign extends Component {
    render() {
        return (
           <Fragment>
                   <Logo/>
           </Fragment>
        )
    }
}

export default LogoDesign