import React,  { Component, Fragment } from 'react';
class Logo extends Component {
    render() {
        return (
           <Fragment>
                    <div className="contentBox">
            <div className="bennerImage">
              <img src={process.env.PUBLIC_URL + "./assets/img/bird.svg"} alt="" />
            </div>
            <div className="bannerText">
              <h1>Logo Design</h1>
              <p>is intelligence made visible</p>
            </div>
          </div>      
           </Fragment>
        )
    }
}

export default Logo