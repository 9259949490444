import React from 'react'

 const FormFeildsData = [
    {
        id:1,
        label:"Graphics Design",
        value:"Graphics Design",
        defaultChecked:true,
        subfeilds:[
            {
                parent_id:1,
                label:"Logo",
                value:"Logo",
                subfeildschild:[
                    {
                        label:"Iconic Logo",
                        value:"Iconic Logo",
                    },
                    {
                        label:"Text Based Logo",
                        value:"Text Based Logo",
                    }
                ]

            },
            {
                parent_id:1,
                label:"Brocher/catelog",
                value:"Brocher/catelog",
            },
            {
                parent_id:1,
                label:"Illustration",
                value:"Illustration",
            },
            {
                parent_id:1,
                label:"Creative Post",
                value:"Creative Post",
            }
            
        ]
    },
    {
        id:2,
        label:"Web Devolopment",
        value:"Web Devolopment",
        defaultChecked:false,
        subfeilds:[
            {
                parent_id:1,
                label:"Logo",
                value:"Logo",
                subfeildschild:[
                    {
                        label:"Iconic Logo",
                        value:"Iconic Logo",
                    },
                    {
                        label:"Text Based Logo",
                        value:"Text Based Logo",
                    }
                ]

            },
            {
                parent_id:1,
                label:"Brocher/catelog",
                value:"Brocher/catelog",
            },
            {
                parent_id:1,
                label:"Illustration",
                value:"Illustration",
            },
            {
                parent_id:1,
                label:"Creative Post",
                value:"Creative Post",
            }
            
        ]
    },
    {
        id:3,
        label:"SEO",
        value:"SEO",
        defaultChecked:false,
        subfeilds:[
            {
                parent_id:1,
                label:"Logo",
                value:"Logo",
                subfeildschild:[
                    {
                        label:"Iconic Logo",
                        value:"Iconic Logo",
                    },
                    {
                        label:"Text Based Logo",
                        value:"Text Based Logo",
                    }
                ]

            },
            {
                parent_id:1,
                label:"Brocher/catelog",
                value:"Brocher/catelog",
            },
            {
                parent_id:1,
                label:"Illustration",
                value:"Illustration",
            },
            {
                parent_id:1,
                label:"Creative Post",
                value:"Creative Post",
            }
            
        ]
    },
   
]
export default FormFeildsData