import React,  { Component, Fragment } from 'react';
import {Link} from "react-router-dom";
import '../common/GlobleVar';
class SideHeader extends Component {
  constructor(propps){
    super(propps);
    this.state  = {
      Active:false,
      }
    this.handleClick = this.handleClick.bind(this);
    this.location = this.useLocation;
   
      }

  handleClick() {
    this.setState({active: !this.state.active})
  
  }
  render() {
    const windowlocation = window.location.pathname
   
        return (
           <Fragment>
           
              <div className={windowlocation === '/' ? 'sideDeheader':'sideDeheader page'}>
            <div className="logosec">
              <button className={ this.state.active ? "menubutton  opened" : "menubutton" }   onClick={this.handleClick}><span />
                <span />
                <span />
              </button> 
              <img className="logo" src={process.env.PUBLIC_URL + "./assets/img/logo.svg"} alt="#" />
              <div className={ this.state.active ? "mainmenu  opened" : "mainmenu" }>
        <ul>
          <li><a href="/" onClick={this.handleClick}>Home</a></li>
          <li><a href="#" onClick={this.handleClick}>About Us</a></li>
          <li><a href="#" onClick={this.handleClick}>Portfolio</a></li>
          <li><a href="#" onClick={this.handleClick}>Clients</a></li>
          <li><a href="#" onClick={this.handleClick}>Blog </a></li>
          <li><a href="#" onClick={this.handleClick}>Contact </a></li>
        </ul>
      </div>
              </div>
            <div className="line" />
            <div className="shortmenuBox">
            
              <ul className="shortmenu">
           
             <li  className={windowlocation === '/GraphicsDesign' ? 'active':''}>
               <Link to='/GraphicsDesign' className={windowlocation === '/GraphicsDesign' ? 'active':'' }>
             <i className= "ews ewse_gaphicsdes" />
                    <span>Graphics</span>
             </Link>
             <Link to='/GraphicsDesign'>
             <i className="ews ewse_gaphicsdes" />
                    <span>Logo & Branding</span>
             </Link>
             <Link to='/GraphicsDesign'>
             <i className="ews ewse_gaphicsdes" />
                    <span>Packaging</span>
             </Link>
             <Link to='/GraphicsDesign'>
             <i className="ews ewse_gaphicsdes" />
                    <span>Advertisments</span>
             </Link>
               
                  </li>
                <li>
                 <Link to='/'>
                 <i className="ews ewse_webdev" />
                    <span>Development</span>
                 </Link>
                 </li>
                <li> <Link to='/'>
                    <i className="ews ewse_digitalmar" />
                    <span>Marketing</span>
                    </Link>
                  </li>
                <li> <Link to='/'>
                    <i className="ews ewse_getquote" />
                    <span>Get Quote</span>
                    </Link>
                </li>
              </ul>
            </div>
            <div className="line" />
            <div className="copyright">© Explosionweb Solutions 2021</div>
          </div>   
           </Fragment>
        )
    }
}

export default SideHeader