import {QuickAction} from '../common/QuickAction';
export const GetQuoteb = () => {
  return(
    <QuickAction label="Get Quote" link="/GetQuote" />
    )
}

export const WhatsApp = () => {
    return(
        <QuickAction  link="#" icon="ews ewse_whatsapp" /> 
      )
  }
  export const Portfolio = (props) => {
      
    return(
        <QuickAction label="Portfolio" link={props.pagelink ==='' ? "/Portfolio" : props.pagelink}/>
      )
  }

  export const Pricing = (props) => {
    return(
        <QuickAction label="Pricing" link={props.pagelink ==='' ? "/Pricing" : props.pagelink}/>
      )
  }